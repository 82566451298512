import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from './firebase';
import { useNavigate } from 'react-router-dom';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { motion } from 'framer-motion';
import './SignUp.css';
import LOGOB from './LOGB.webp';
import LOGB1 from './LOGB1.webp';
import SIGN from './SIGN.webp';
import SIGN5 from './SIGN5.webp'; // Import SIGN5.webp for the signup button
import ALLBOX from './ALLBOX.webp'; // Import ALLBOX.webp for input backgrounds

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (!email || !password || !confirmPassword || !username) {
      setError('Please fill out all fields.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      // Check if username is already taken
      const usernameExists = await checkUsernameExists(username);
      if (usernameExists) {
        setError('Username already exists. Please choose another one.');
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userData = {
        email: user.email,
        username: username,
        uid: user.uid,
        createdAt: new Date()
      };

      // Set the document ID as the username
      await setDoc(doc(db, 'users', username), userData);
      console.log('User registered successfully:', user.uid);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  // Function to check if username exists
  const checkUsernameExists = async (username) => {
    const userDoc = await getDoc(doc(db, 'users', username));
    return userDoc.exists();
  };

  return (
    <div className="signup-bg" style={{ backgroundImage: `url(${LOGOB})` }}>
      <motion.div
        className="signup-container"
        style={{ backgroundImage: `url(${LOGB1})` }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.img
          src={SIGN}
          alt="Sign Up"
          className="signup-title"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        />
        <motion.input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="signup-input"
          whileFocus={{ scale: 1.05 }}
          style={{ backgroundImage: `url(${ALLBOX})` }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        />
        <motion.input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="signup-input"
          whileFocus={{ scale: 1.05 }}
          style={{ backgroundImage: `url(${ALLBOX})` }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        />
        <motion.input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="signup-input"
          whileFocus={{ scale: 1.05 }}
          style={{ backgroundImage: `url(${ALLBOX})` }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        />
        <motion.input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="signup-input"
          whileFocus={{ scale: 1.05 }}
          style={{ backgroundImage: `url(${ALLBOX})` }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.0 }}
        />
        <motion.button
          className="signup-button"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.2 }}
          onClick={handleSignUp}
        >
          <img src={SIGN5} alt="Sign Up Button" className="signup-button-image" />
        </motion.button>
        {error && (
          <motion.p
            className="signup-error"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.4 }}
          >
            {error}
          </motion.p>
        )}
      </motion.div>
    </div>
  );
};

export default SignUp;
